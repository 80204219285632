import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Button from './Button';
import image from '../static/contact.jpg';
import './styles/Contact.css';

function Contact() {
    const [btnText, setBtnText] = useState('Send Email');
    const form = useRef();


    const sendEmail = (e) => {
        setBtnText('Sending...');
        e.preventDefault();

        try {

            emailjs.sendForm('service_up9s1ng', 'template_6oln9yn', form.current, 'ron4atVwTztK4Lgh4')
            .then(() => {
                setBtnText('Email Sent!');
            });
        } catch(err) {
            console.error(err);
            setBtnText('Sending failed.');
        } 
    };


    return (
        <div className='contact-container' id="contact">
            <div className="container">
                <div className="contact-image">
                    <img src={image} alt="Contact" />
                </div>
                <div className="contact-content">
                    <div className="contact-message">
                        <p>Contact Us</p>
                    </div>
                    <form className="contact-form" ref={form} onSubmit={sendEmail}>
                        <div className="double-input">
                            <div className="input-container">
                                <label htmlFor='from_name'>Name</label>
                                <input type="text" name="from_name" id="from_name" className='input' placeholder='Name...' />
                            </div>
                            <div className="input-container">
                                <label htmlFor='from_email'>Email</label>
                                <input type="email" name="from_email" id="from_email" className='input' placeholder='Email...' />
                            </div>
                        </div>
                        <div className="input-container">
                            <label htmlFor='message'>Message</label>
                            <textarea className='input' name="message" id="message" placeholder='Message...' rows={12} cols={50} ></textarea>
                        </div>
                        <Button innerText={btnText} type="primary" addClasses={'btn-stretch'}/>
                    </form>
                </div>
            </div>
        </div>
  )
}

export default Contact