const validateName = name => {
	if (!name) {
		return {
			isValid: false,
			msg: 'Name is required.'
		};
	}

	name = name.trim();
	const validNameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;

	if (!name)
		return {
			isValid: false,
			msg: 'Name is required.'
		};

	if (name.length <= 1)
		return {
			isValid: false,
			msg: 'Name must be longer than 1 character.'
		};

	if (!validNameRegex.test(name))
		return {
			isValid: false,
			msg: 'Name must only contain letters.'
		};

	return {
		isValid: true,
		msg: null
	};
};

const validateEmail = email => {
	if (!email)
		return {
			isValid: false,
			msg: 'Email is required.'
		};

	email = email.trim();
	const validEmailRegex =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	if (!email)
		return {
			isValid: false,
			msg: 'Email is required.'
		};

	if (!validEmailRegex.test(email))
		return {
			isValid: false,
			msg: 'Email is not valid.'
		};

	return {
		isValid: true,
		msg: ''
	};
};

const validatePhone = phone => {
	if (!phone)
		return {
			isValid: false,
			msg: 'Phone is required'
		};

	phone = phone.trim();
	const validPhoneRegex =
		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

	if (!phone)
		return {
			isValid: false,
			msg: 'Phone is required.'
		};

	if (!validPhoneRegex.test(phone))
		return {
			isValid: false,
			msg: 'Phone is not valid.'
		};

	return {
		isValid: true,
		msg: ''
	};
};

const validator = {
	validateName,
	validateEmail,
	validatePhone
};

export default validator;
