import React, { useEffect, useState } from 'react'
import CartItemSmall from './CartItemSmall';
import Loading from './Loading';
import './styles/OrderSummary.css';

function OrderSummary({cart}) {
    const [tax, setTax] = useState(0);


    useEffect(() => {
        if (!cart) return;
        const tax = cart.subtotal.raw * 5.5 / 100;
        setTax(tax);
    }, [cart]);

    return (
        <div className="order-summary">
            <div className="order-summary-container">
                <h2>Order Summary</h2>
                {!cart && <Loading />}
                {cart && cart.line_items.map(product => 
                    <CartItemSmall product={product} key={product.id} />
                    )}
                {cart && <div className="cart-total">
                    <h3>Total</h3>
                    <div className="cart-price price">
                        <p>Cart Total: </p>
                        <p>${cart.subtotal.formatted}</p>
                    </div>
                    <div className="tax-price price">
                        <p>Tax: </p>
                        <p>${tax.toFixed(2)}</p>
                    </div>
                    <div className="total-price price">
                        <p>Total: </p>
                        <p>${(tax + cart.subtotal.raw).toFixed(2)}</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default OrderSummary