import React from 'react'
import './styles/Error.css';

function Error({ msg }) {

    return (
        <div className='error-container'>
            <div className="error-icon">
                <i className="fa-solid fa-triangle-exclamation"></i>
            </div>
            <div className="error-msg">
                <p>{msg}</p>
            </div>
        </div>
    )
}

export default Error