import React from 'react';
import LandingBanner from '../components/LandingBanner';
import BestSellers from '../components/BestSellers';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

function LandingPage({ scrollTo }) {
  return (
    <>
    <ScrollToTop scrollTo={scrollTo} />
    <LandingBanner/>
    <BestSellers />
    <About />
    <Contact />
    <Footer />
    </>
  )
}

export default LandingPage