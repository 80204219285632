import React from 'react'
import './styles/CartItemSmall.css'

function CartItemSmall({ product }) {
  return (
    <div className='cart-item-summary-container'>
        <div className="item-content">
            <div className="item-img" style={{backgroundImage: `url("${product.image.url}")`}}>
            </div>
            <div className="item-text">
                <div className="item-title">{product.name}</div>
                <div className="item-desc">{product.sku}</div>
            </div>
        </div>
        <div className="item-price"><span className='quantity'>{product.quantity}x </span>${product.price.formatted}</div>
    </div>
  )
}

export default CartItemSmall