import React, { useEffect, useState } from 'react'
import Button from './Button'
import './styles/CheckoutSection.css';

function CheckoutSection({ cartTotal }) {
    const [tax, setTax] = useState(0);
    const [priceWithTax, setPriceWithTax] = useState(0);
    useEffect(() => {
        const taxAmount = cartTotal.raw / 100 * 5.5;
        setTax(taxAmount.toFixed(2));
        const rawPriceWithTax = cartTotal.raw + taxAmount;
        setPriceWithTax(rawPriceWithTax.toFixed(2));
    }, [cartTotal.raw]);

  return (
    <div className='checkout-section'>
        <h3>Subtotal</h3>
        <div className="price cart-price">
            <p>Cart:</p>
            <p className='right'>${cartTotal.formatted}</p>
        </div>
        <div className="price tax-price">
            <p>Tax:</p>
            <p className='right'>${tax}</p>
        </div>
        <div className="price total-price">
            <p>Total:</p>
            <p className='right'>${priceWithTax}</p>
        </div>
        <Button innerText={'Checkout'} type='primary' addClasses={'btn-stretch'} linkTo={'/checkout'} disabled={cartTotal.raw === 0} />
    </div>
  )
}

export default CheckoutSection