import React from 'react'
import { Link } from 'react-router-dom'
import './styles/CartItem.css'

function CartItem({ product, onDelete, onQuantityChange }) {

    return (
        <div className='cart-item'>
            <div className="item-description">
                <Link to={`/product/${product.id}`}>
                    <div className="item-img" style={{
                        backgroundImage: `url("${encodeURI(product.image.url)}")`
                    }}>
                    </div>
                </Link>
                <Link to={`/product/${product.id}`}>
                <div className="item-content">
                    <h3>{product.name}</h3>
                    <p className='desc'>{product.sku}</p>
                    <p className='single-price'>${product.price.formatted}</p>
                </div>
                </Link>
            </div>
            <div className="item-quantity">
                <div className="quantity-control">
                    <div className="minus" onClick={() => onQuantityChange(product, -1)}>-</div>
                    <p className="quantity">{product.quantity}</p>
                    <div className="plus" onClick={() => onQuantityChange(product, 1)}>+</div>
                </div>
            </div>
            <div className="item-total">
                <p>${product.line_total.formatted}</p>
            </div>
            <div className="cart-item-delete" onClick={() => onDelete(product)}>
                <p>x</p>
            </div>
        </div>
    )
}

export default CartItem