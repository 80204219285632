import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({scrollTo}) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!scrollTo) return window.scrollTo(0, 0);

    const elementToScrollTo = document.querySelector(`#${scrollTo}`);
    setTimeout(() => {
        window.scroll(0, elementToScrollTo.offsetTop - 100);
    }, 700);

  }, [pathname, scrollTo]);

  return null;
}