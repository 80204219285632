import React, { useContext } from 'react'
import Button from './Button';
import { Context } from '../context';
import { useNavigate } from 'react-router-dom';

import './styles/Product.css';

function Product({ product, setErr }) {
    const navigate = useNavigate();

    const {commerce, setNumOfItemsInCart} = useContext(Context);

    const addToCart = () => {
        if (!commerce) return;

        try {
            setNumOfItemsInCart(prevNum => prevNum + 1);
            commerce.cart.add(product.id, 1);
        } catch(err) {
            setNumOfItemsInCart(prevNum => prevNum - 1);
            console.error(err);
            setErr('Adding to cart failed. Please try again later.');
        }
    }  

    const redirect = () => {
        navigate(`/product/${product.id}`);
    }

    return (
        <div className='product-container'>
            <div className="product-img" onClick={redirect}>
                <img src={product.image.url} alt={product.name}></img>
            </div>
            <div className="product-content" onClick={redirect}>
                <p className='product-name'>{product.name}</p>
                <p className="product-description">{product.sku}</p>
                <p className='product-price'>{`$${product.price.formatted}`}</p>
            </div>
            <Button innerText={'Add To Cart'} type={'primary'} addClasses="btn-stretch btn-small" onClick={addToCart}></Button>
        </div>
    )
}

export default Product