import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import Billing from '../components/Billing'
import Error from '../components/Error';
import Loading from '../components/Loading'
import Navbar from '../components/Navbar'
import OrderSummary from '../components/OrderSummary'
import ScrollToTop from '../components/ScrollToTop'
import { Context } from '../context'
import './styles/Checkout.css'


function Checkout() {
    const [cart, setCart] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);

    const navigate = useNavigate();

    const { commerce, setNumOfItemsInCart } = useContext(Context);

    const setTax = useCallback(async (checkoutToken, cartToTax) => {
        const requestBody = {
            tax: {
              provider: 'Dragonfly',
              line_items: cartToTax.line_items.map(cartItem => ({
                id: cartItem.id,
                breakdown: [
                  {
                    amount: calculateTax(cartItem) / cartToTax.line_items.length,
                    rate: 0.055,
                    type: 'Tax',
                  }
                ],
              }))
            },
          };


        try {
            // Add the calculated custom tax response by TaxJar to
            // the checkout using Chec's Update Checkout API
            let response = await fetch(
            `https://api.chec.io/v1/checkouts/${checkoutToken.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Authorization': 'sk_522288d3fe753f9f5ad4e04ab120d6030bf31ce2bdc5d',
              },
              body: JSON.stringify(requestBody)
            });

            const newCheckoutToken = await response.json();
            setToken(newCheckoutToken);

          } catch (err) {
            console.log('Error', err)
            setErr('Error calculating tax. Please try again later.');
          }
    }, [])

    const calculateTax = item => {
        return item.line_total.raw / 100 * 5.5;
    }

    const captureOrder = async (tokenId, newOrder) => {
        setLoading(true);
        try {
            await commerce.checkout.capture(tokenId, newOrder);
            setNumOfItemsInCart(0);
            navigate('/confirmation');
            refreshCart();
            setLoading(false);
        } catch(err) {
            console.error(err);
            setLoading(false);
            setErr('Error capturing order. Please try again later.');
        }
    }

    useEffect(() => {
        try {
            commerce.cart.retrieve().then(cart => {
                setCart(cart);
                generateToken(cart);
            });
        } catch(err) {
            setErr('Error fetching cart. Please try again later.');
            console.error(err);
        }
        
        const generateToken = cart => {
            try {
                commerce.checkout.generateToken(cart.id, {type: 'cart'}).then(token => {
                    setToken(token);
                    setTax(token, cart);

                    return token;
                });
            } catch(err) {
                console.error(err);
                setErr('Error generating checkout token. Please try again later.');
            }
        }

    }, [commerce.cart, commerce.checkout, setTax]);


    const refreshCart = () => {
        try {
            commerce.cart.refresh();
            setNumOfItemsInCart(0);
        } catch(err) {
            console.error(err);
            setErr('Error clearing cart. Please try again later.');
        }
    }

    return (
        <>
        <ScrollToTop />
        <Navbar />
        <div className="checkout-page-container">
            {err && 
                <div className="container">
                    <Error msg={err} />
                </div>
            }
            <div className="container">
                {loading && !err && <Loading />}
                {!loading && <>
                    <Billing token={token} captureOrder={captureOrder} setErr={setErr} />
                    <OrderSummary cart={cart} />
                </>}
            </div>
        </div>
        </>
    )
}

export default Checkout