import React from 'react';
import Button from '../components/Button';
import Navbar from '../components/Navbar';
import './styles/Confirmation.css';


function Confirmation() {
  return (
    <>
        <Navbar />
        <div className="confirmation-container container">
            <div className="icon-container">
                <i className="fa-solid fa-shop"></i>
            </div>
            <div className="thankyou-container">
                <h2>Thank You!</h2>
            </div>
            <div className="text-container">
                <p>A confirmation has been sent to your email.</p>
                <p>We hope you enjoyed your experience with us.</p>
            </div>
            <div className="reminder-container">
                <p><span className='bold'>Reminder: </span> Your fresh flowers can be picked up at 2800 Svannah Drive everyday from 7am to 5pm.</p>
            </div>
            <Button type='primary' innerText={"Continue Shopping"} linkTo="/shop"/>
        </div>
    </>
    
  )
}

export default Confirmation