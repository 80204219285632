import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import BestSellers from '../components/BestSellers';
import Button from '../components/Button';
import Error from '../components/Error';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import Navbar from '../components/Navbar';
import ScrollToTop from '../components/ScrollToTop';
import { Context } from '../context';
import './styles/ProductPage.css';

function ProductPage() {
    const { products, commerce, setNumOfItemsInCart } = useContext(Context);
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [productImages, setProductImages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [mainImg, setMainImg] = useState(null);
    const [error, setError] = useState(null);

    const getProductImages = useCallback(prodId => {
        setLoading(true);

        try {
            commerce.products.retrieve(prodId).then(product => {
                setProductImages(product.assets);
                setLoading(false);
            });
        } catch(err) {
            console.error(err);
            setError('Error fetching product images. Please try again later.');
            setLoading(false);
        }
    }, [commerce.products]);


    useEffect(() => {
        if (!products || products?.length <= 0) return;

        const currProduct = products.find(someProduct => someProduct.id === id);
        setMainImg(currProduct.image.url);
        setProduct(currProduct);

        getProductImages(id);
    },  [getProductImages, id, products]);

    const changeQuantityBy = changeAmount => {
        let newQnty = quantity + changeAmount;

        if (newQnty <= 0) newQnty = 1;
        
        setQuantity(newQnty);
    }

    const formatDescription = desc => {
        desc = desc.replace('<p>', '');
        desc = desc.replace('</p>', '');

        return desc;
    }

    const addToCart = () => {
        if (!commerce) return;

        try {
            setNumOfItemsInCart(prevNum => prevNum + quantity);
            commerce.cart.add(product.id, quantity);
        } catch(err) {
            setNumOfItemsInCart(prevNum => prevNum - quantity);
            console.error(err);
            setError('Adding to cart failed. Please try again later.');
        }
    }  

    return (
        <>
        <ScrollToTop />
        <Navbar />
        {product && <div className="product-page-container container">
            {error && <Error msg={error} />}
            <div className="product-images">
            {loading && <Loading />}
                {!loading && <>
                    <div className="small-images">
                        {productImages.map(image => 
                            <div className='small-image' style={{
                                backgroundImage: `url("${image.url}")`
                            }}
                            onClick={() => setMainImg(image.url)}
                            key={image.url}>
                            </div>
                        )}
                    </div>
                    <div className="main-image">
                        <img src={mainImg} alt={product.name} />
                    </div>
                    <p className="small-screen-short-desc">{product.sku}</p>
                    <h2 className="small-screen-title">{product.name}</h2>
                </>}
            </div>
            <div className="single-product-content">
                <div className="prd-name">
                    <h2>{product.name}</h2>
                </div>
                <div className="prd-short-desc">
                    <p>{product.sku}</p>
                </div>
                <div className="prd-price">
                    <p>${product.price.formatted}</p>
                </div>
                <div className="prd-description">
                    <p className='desc-label-container'><span className="description-label">Specifications</span></p>
                    <p>
                        {formatDescription(product.description)}
                    </p>
                </div>
                <div className="prd-buttons">
                    <div className="quantity-btn">
                        <p className='text'>Qnty</p>
                        <p className='minus' onClick={() => changeQuantityBy(-1)}>-</p>
                        <p className='quantity'>{quantity}</p>
                        <p className='plus' onClick={() => changeQuantityBy(1)}>+</p>
                    </div>
                    <Button type={'primary'} innerText={'Add To Cart'} onClick={addToCart} />
                </div>
            </div>
        </div>}
        <BestSellers />
        {!loading && <Footer />}
        </>
    )
}

export default ProductPage