import React from 'react';
import Navbar from './Navbar';
import Button from './Button';
import './styles/LandingBanner.css';

import left1 from '../static/Left1.png';
import left2 from '../static/Left2.png';
import left3 from '../static/Left3.png';
import right1 from '../static/Right1.png';
import right2 from '../static/Right2.png';

function LandingBanner() {
  return (
    <div className='landing-page-container'>
        <Navbar />
        <div className="photo-gallery">
            <div className="photos left">
                <img src={left2} alt="Peony"></img>
                <img src={left1} alt="Peony"></img>
                <img src={left3} alt="Peony"></img>
            </div>
            <div className="photos right">
                <img src={right1} alt="Peony"></img>
                <img src={right2} alt="Peony"></img>
            </div>
        </div>
        <div className="container landing-content">
            <div className="main-landing-content">
                <div className="text-container">
                    <p className="background-text">THE MOST BEAUTIFUL LOCALLY GROWN PEONIES</p>
                    <h2 className='main-text'>Best Flowers <br /> for any occasion</h2>
                </div>
            </div>
            <div className="btn-container">
                <Button type={'primary'} innerText='Explore Our Store' addClasses='btn-large' iconClass={"fa-solid fa-chevron-right"} linkTo="/shop" />
            </div>
        </div>
    </div>
  )
}

export default LandingBanner