import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Button.css'

function Button({type, innerText, addClasses, iconClass, linkTo, onClick, disabled}) {
    const navigate = useNavigate();
    const handleOnClick = e => {
        if (onClick) onClick(e);
        if (linkTo) navigate(linkTo);
    }
  return (
    <>
    <button className={`btn btn-${type} ${addClasses} ${disabled && 'btn-disabled'}`} onClick={e => handleOnClick(e)} disabled={disabled}>
        {innerText}
        {iconClass && <i className={iconClass}></i>}
    </button>
    </>
  )
}

export default Button