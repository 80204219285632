import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../context';
import Loading from './Loading';
import Product from './Product';
import Error from './Error';
import './styles/ProductsList.css';

function ProductsList({productCategories, header, hasSearchBar, limit, id}) {
    const { products } = useContext(Context);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [err, setErr] = useState(null);
  
    useEffect(() => {
        if (!productCategories || productCategories.length <= 0) return setFilteredProducts(products);        
    
        const filteredArr = filterProductsByCategories(products, productCategories);

        const limitedProductArr = limitArrayLength(filteredArr, limit);
        setFilteredProducts(limitedProductArr);
    }, [limit, productCategories, products]);

    const filterProductsByCategories = (productArr, categories) => {
        const filteredArr = [];

        productArr.forEach(product => {
            const currProductCategories = product.categories.map(category => category.slug);

            const currProductFitsListCategories = categories.every(category => currProductCategories.includes(category));

            if (currProductFitsListCategories) filteredArr.push(product);
        });

        return filteredArr;
    }
    
    const limitArrayLength = (arr, limit) => {
        if (!limit) return arr;
        if (limit <= 0) return [];
        
        return arr.slice(0, limit);
    }
    

  
    if (filteredProducts && filteredProducts.length <= 0) return;

    return (
    <div className='container' id={id}>
        
        {header && 
        <div className="product-list-header">
            <h3>{header}</h3>
            {hasSearchBar && <div className="search-bar">
                <i className="fas fa-search"></i>
                <input type='text' placeholder='Search...'/>
            </div>}
        </div>}

        {err && <Error />}

        <div className="product-list-container">
        {!err && !filteredProducts && <Loading />}
        {!err && filteredProducts && filteredProducts.map(product =>
           <Product categories={productCategories} product={product} key={product.id} setErr={setErr} />
        )}
        </div>
    </div>
  )
}

export default ProductsList