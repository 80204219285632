import React, { useEffect, useState, useContext } from 'react'
import Navbar from '../components/Navbar'
import CartItemsList from '../components/CartItemsList'
import CheckoutSection from '../components/CheckoutSection'
import './styles/CartPage.css';
import BestSellers from '../components/BestSellers';
import Footer from '../components/Footer';
import { Context } from '../context';
import Loading from '../components/Loading';
import ScrollToTop from '../components/ScrollToTop';
import Error from '../components/Error';

function CartPage() {
    const { commerce, setNumOfItemsInCart, numOfItemsInCart } = useContext(Context);
    const [cart, setCart] = useState(null);
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDelete = product => {
        setLoading(true);
        
        try {
            commerce.cart.update(product.id, {quantity: 0}).then(() => {
                setNumOfItemsInCart(numOfItemsInCart - product.quantity);
                setLoading(false);
            });
        } catch(err) {
            console.error(err);
            setLoading(false);
            setErr('Deleting product failed. Please try again.');
        }
    }
    
    const onQuantityChange = (product, quantityChange) => {
        setLoading(true);
        
        try {
            commerce.cart.update(product.id, {quantity: product.quantity + quantityChange}).then(() => {
                setNumOfItemsInCart(numOfItemsInCart + quantityChange);
                setLoading(false);
            });
        } catch(err) {
            console.error(err);
            setLoading(false);
            setErr('Updating product quantity failed. Please try again.');
        }
    };

    useEffect(() => {
        setLoading(true);
        console.log('setting to true');
        
        try {
            commerce.cart.retrieve().then(cart => {
                setCart(cart);
                setLoading(false);
            });
        } catch(err) {
            console.error(err);
            setErr('Fetching cart failed. Please try again later.')
            setLoading(false);
        }
    }, [commerce.cart]);

    return (
        <>
            <ScrollToTop />
            <Navbar />
            <div className="container cart-page-container">
                {err && <Error msg={err}/>}
                {loading && <Loading />}
                {cart && !loading && 
                <>
                    <CartItemsList products={cart.line_items} onDelete={onDelete} onQuantityChange={onQuantityChange} />
                    <CheckoutSection cartTotal={cart.subtotal} />
                    <BestSellers />
                </>}
            </div>
            {cart && !loading && <Footer />}
        </>
    )
}

export default CartPage