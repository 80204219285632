import React from 'react';
import './styles/About.css';
import image from '../static/about1.jpg';
import Button from './Button';

function About() {
  return (
    <div className='about-container'>
        <div className="container">

            <div className="about-main-content" id="about">
                <p className="main-text">We have a mission. Our flowers always leave our customers happy and content.</p>
            </div>
            <div className="about-side-content">
                <p className='side-text'>We are a local farm based in Wisconsin with a clear mission: provide the best flowers possible for you! We promise you, with our flowers and services, you will be the talk of the town. We are a small company who is willing to work directly with you without any wait time. No more "on hold", no more waiting. Just beautiful, soul-filling flowers. We offer pre-made products with lots of variety. Of course, we also provide services for weddings and other events, if that is more your style.</p>
            </div>
            <div className="about-image">
                <img src={image} alt="Our flower farm" className="about-img" />
            </div>
            <div className="phone-about-content">
                <div className="about-header">
                    <p>ABOUT US</p>
                    <h2>Dragonfly Dream Peonies</h2>
                </div>
                <div className="about-text-section">
                    <div className="text-container">
                        <p className="main-p">We have a mission: Provide the best possible flowers for you.</p>
                    </div>
                    <div className="text-container">
                        <div className="text-label">
                            <i className="fa-solid fa-book-open"></i>
                            OUR STORY</div>
                        <p>We are a local farm based in Wisconsin where we do what we love: grow flowers. We promise you, with our flowers and services, you will be the talk of the town.</p>
                    </div>
                    <div className="text-container">
                        <div className="text-label">
                            <i className="fa-solid fa-brain"></i>
                            OUR PHILOSOPHY</div>
                        <p>We are a small company who is willing to work directly with you without any wait time. No more "on hold", no more waiting. Just beautiful, soul-filling flowers.</p>
                    </div>
                    <div className="text-container">
                        <div className="text-label">
                            <i className="fa-solid fa-seedling"></i>
                            OUR PRODUCTS</div>
                        <p>We offer pre-made products with lots of variety. Of course, we also provide services for weddings and other events, if that is more your style.</p>
                    </div>
                    <Button linkTo={'/shop'} type="primary" innerText={'Explore Our Store'} addClasses={'btn-stretch'} iconClass={"fa-solid fa-chevron-right"} />
                </div>
                <div className="small-about-img">
                    <img src={image} alt="Our flower farm" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default About