import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
        <div className="container">
            <div className="logo-container">
                <Link to="/">Dragonfly Dream Peonies <br/> 2023</Link>
            </div>
            <div className="links-container">
                <Link to={'/about'}>About Us</Link>
                <Link to={'/contact'}>Contact Us</Link>
                <Link to={'https://www.facebook.com'}>Facebook</Link>
                <Link to={'https://www.instagram.com'}>Instagram</Link>
            </div>
            <div className="shop-container">
                <Link to={'/shop'}>Products</Link>
                <Link to={'/services'}>Services</Link>
                <Link to={'/events'}>Events</Link>
            </div>
        </div>
    </div>
  )
}

export default Footer