import React from 'react';
import img1 from '../static/Left1.png'
import img2 from '../static/Left2.png'
import img3 from '../static/Right1.png'
import './styles/ProductsBanner.css';

function ProductsBanner() {
  return (
    <div className="products-banner">
        <div className="container intro-container">
            <div className="text-container">
                <h2>High Quality, <br /> High Satisfaction</h2>
                <p>Browse our collection of products made just for you</p>
            </div>
            <div className="photo-container">
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
            </div>
        </div>
    </div>
  )
}

export default ProductsBanner