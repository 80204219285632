import React from 'react'
import { Link } from 'react-router-dom'
import CartItem from './CartItem'
import './styles/CartItemsList.css'

function CartItemsList({ products, onDelete, onQuantityChange }) {
  return (
    <div className='cart-list-container container'>
        <div className="text-layer">
            <h2>Your Cart</h2>
            <Link to={'/shop'}>Continue Shopping</Link>
        </div>
        {products.length <= 0 && <p className='empty-cart'>Your cart is empty. Start filling it up!</p>} 
        {products.length >= 1 && <div className="list">
            <div className="list-header">
                <p>PRODUCT</p>
                <p>QUANTITY</p>
                <p>PRICE</p>
            </div>
            <div className="list-items">
                {products.map(product => <>
                    <CartItem product={product} key={product.id} onDelete={onDelete} onQuantityChange={onQuantityChange} />
                </>)}
            </div>
        </div>}
    </div>
  )
}

export default CartItemsList