import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Commerce from '@chec/commerce.js';
import LandingPage from './pages/LandingPage';
import Shop from './pages/Shop';
import { Context } from './context';
import CartPage from './pages/CartPage';
import Checkout from './pages/Checkout';
import Confirmation from './pages/Confirmation';
import Gallery from './pages/Gallery';
import ProductPage from './pages/ProductPage';

function App() {
	const [products, setProducts] = useState([]);
	const [numOfItemsInCart, setNumOfItemsInCart] = useState({});
	const commerce = new Commerce(
		'pk_52228d85e29b56fb8984cb2482ec4f04e1111caa64228'
	);

	useEffect(() => {
		try {
			commerce.products.list().then(productData => {
				if (!productData.data || productData.data.length <= 0) return;

				setProducts(productData.data);
			});
		} catch (err) {
			console.error(err);
		}

		try {
			commerce.cart.retrieve().then(cartData => {
				setNumOfItemsInCart(cartData.total_items);
			});
		} catch (err) {
			console.error(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="App">
			<Context.Provider
				value={{
					commerce,
					products,
					numOfItemsInCart,
					setNumOfItemsInCart
				}}>
				<Router>
					<Routes>
						<Route exact path="/" element={<LandingPage />} />
						<Route
							exact
							path="/about"
							element={<LandingPage scrollTo={'about'} />}
						/>
						<Route
							exact
							path="/contact"
							element={<LandingPage scrollTo={'contact'} />}
						/>
						<Route exact path="/shop" element={<Shop />} />
						<Route
							exact
							path="/services"
							element={<Shop scrollTo="services" />}
						/>
						<Route
							exact
							path="/events"
							element={<Shop scrollTo="events" />}
						/>
						<Route exact path="/cart" element={<CartPage />} />
						<Route exact path="/checkout" element={<Checkout />} />
						<Route
							exact
							path="/confirmation"
							element={<Confirmation />}
						/>
						<Route exact path="/gallery" element={<Gallery />} />
						<Route
							exact
							path="/product/:id"
							element={<ProductPage />}
						/>
					</Routes>
				</Router>
			</Context.Provider>
		</div>
	);
}

export default App;
