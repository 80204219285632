import React from 'react';
import './styles/BestSellers.css';
import ProductsList from './ProductsList';
import { Link } from 'react-router-dom';

function BestSellers() {
  return (
    <div className="best-sellers-container">
        <div className="container">
            <div className="products-text">
                <h2>Best Sellers</h2>
                <Link to={'/shop'}>View All</Link>
            </div>
            <ProductsList limit={4} productCategories={['product']} />
        </div>
    </div>
  )
}

export default BestSellers