import React from 'react'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ScrollToTop from '../components/ScrollToTop';
import img1_1 from '../static/gallery/1_1.png';
import img1_2 from '../static/gallery/1_2.png';
import img1_3 from '../static/gallery/1_3.png';
import img1_4 from '../static/gallery/1_4.png';
import img2_1 from '../static/gallery/2_1.png';
import img2_2 from '../static/gallery/2_2.png';
import img3_1 from '../static/gallery/3_1.png';
import img3_2 from '../static/gallery/3_2.png';
import img3_3 from '../static/gallery/3_3.png';
import img3_4 from '../static/gallery/3_4.png';
import img4_1 from '../static/gallery/4_1.png';
import img4_2 from '../static/gallery/4_2.png';
import img4_3 from '../static/gallery/4_3.png';
import img4_4 from '../static/gallery/4_4.png';
import './styles/Gallery.css';

function Gallery() {
  return (
    <>
        <ScrollToTop />
        <Navbar />
        <div className="gallery-container container">
            <h2>Our Gallery</h2>
            <div className="gallery">
                <div className="four-layer">
                    <img src={img1_1} alt="Flowers." className="gallery-img" />
                    <img src={img1_2} alt="Flowers." className="gallery-img" />
                    <img src={img1_3} alt="Flowers." className="gallery-img" />
                    <img src={img1_4} alt="Flowers." className="gallery-img" />
                </div>
                <div className="two-layer">
                    <div className="img-cont">
                        <img src={img2_1} alt="Flowers." className="gallery-img" />
                    </div>
                    <div className="img-cont">
                        <img src={img2_2} alt="Flowers." className="gallery-img" />
                    </div>
                </div>
                <div className="four-layer">
                    <img src={img3_1} alt="Flowers." className="gallery-img" />
                    <img src={img3_2} alt="Flowers." className="gallery-img" />
                    <img src={img3_3} alt="Flowers." className="gallery-img" />
                    <img src={img3_4} alt="Flowers." className="gallery-img" />
                </div>
                <div className="four-layer">
                    <img src={img4_1} alt="Flowers." className="gallery-img" />
                    <img src={img4_2} alt="Flowers." className="gallery-img" />
                    <img src={img4_3} alt="Flowers." className="gallery-img" />
                    <img src={img4_4} alt="Flowers." className="gallery-img" />
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Gallery