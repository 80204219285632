import React, { useState } from 'react';
import './styles/Input.css';

function Input({type, name, id, label, placeholder, value, onChange, validatorFunc, checked}) {
    const [isValid, setIsValid] = useState(null);

    const handleInput = e => {
        if (!onChange) return;

        if (type === 'radio') return handleRadioInput(e);

        const newValue = e.target.value;

        let isNewValueValid = null;
        if (validatorFunc) {
            const validityData = validatorFunc(newValue);
            isNewValueValid = validityData.isValid;
    
    
            setIsValid(isNewValueValid);
        }

        onChange(id, newValue, isNewValueValid);

    }

    const handleRadioInput = e => {
        setIsValid(true);

        const nameOfRadioInput = e.target.name;
        onChange(nameOfRadioInput, value, true);
    }

    return (
        <div className={`input-container ${isValid === false && 'not-valid'}`}>
            {label && <label htmlFor={id}>{label}</label>}
            <input type={type} name={name} id={id} placeholder={placeholder} onChange={e => handleInput(e)} value={value} checked={checked} />
        </div>
    )
}

export default Input