import React from 'react'
import Navbar from '../components/Navbar'
import './styles/Shop.css';
import ProductsBanner from '../components/ProductsBanner';
import ProductsList from '../components/ProductsList';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

function Shop({scrollTo}) {

    return (
        <>
        <ScrollToTop scrollTo={scrollTo} />
        <Navbar />
        <ProductsBanner />
        <div className="container products-content">
            
            <ProductsList productCategories={['product']} id="products" header={'Trending'} hasSearchBar />
            <ProductsList productCategories={['event']} id="events" header={'Events'} />
            <ProductsList productCategories={['service']} id="services" header={'Services'} />
        </div>

        <Footer />
        </>
    )
}

export default Shop