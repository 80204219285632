import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../context';
import './styles/Navbar.css';
import logo from '../static/logo.png';

function Navbar() {
    const { numOfItemsInCart } = useContext(Context);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        toggleFixedNavbar();
    }, []);

    const toggleFixedNavbar = () => {
        const navbar = document.querySelector('.navbar');
        const navbarText = document.querySelector('.company-link');

        document.addEventListener('scroll', () => {

            if (window.scrollY  > 3) {
                navbar.style.paddingTop = '1.5rem';
                navbarText.style.fontSize = '1.5rem';
                return;
            }
            
            navbarText.style.fontSize = '1.7rem';
            navbar.style.paddingTop = '3rem';
        });
    } 

    const handleMenuToggle = e => {
        if (e.target.classList.contains('phone-menu')) {
            setShowMenu(false);
        }
    }


  return (
    <div className='navbar'>
        <div className="container">
            <div className="static-menu">
                <div className="menu-icon-container" onClick={() => setShowMenu(true)}>
                    <div className="menu-icon"></div>
                </div>
                <div className="logo-container">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="menu-list-container static">
                    <ul className='menu-list list-left'>
                        <li className='menu-link'><Link to="/about">ABOUT</Link></li>
                        <li className='menu-link'><Link to="/contact">CONTACT</Link></li>
                        <li className='menu-link'><Link to="/gallery">GALLERY</Link></li>
                    </ul>
                </div>
            </div>
            <div className="company-info">
                <h1 className='company-link'><Link to='/'>DRAGONFLY <br className='word-break' />DREAM PEONIES</Link></h1>
            </div>
            <div className="shop-menu">
                <div className="menu-list-container shop">
                    <ul className='menu-list list-right'>
                        <li className='menu-link'><Link to="/shop">SHOP</Link></li>
                        <li className='menu-link'><Link to="/services">SERVICES</Link></li>
                        <li className='menu-link'><Link to="/events">EVENTS</Link></li>
                    </ul>
                </div>
                <Link to="/cart">
                    <div className="cart-container">
                        <svg className='cart-icon' width="30" height="30" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.6666 12.8333V8.16667C18.6666 6.92899 18.175 5.742 17.2998 4.86683C16.4246 3.99167 15.2376 3.5 14 3.5C12.7623 3.5 11.5753 3.99167 10.7001 4.86683C9.82496 5.742 9.33329 6.92899 9.33329 8.16667V12.8333M5.83329 10.5H22.1666L23.3333 24.5H4.66663L5.83329 10.5Z" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {numOfItemsInCart > 0 && <div className="item-num-showcase">{numOfItemsInCart}</div>}
                    </div>
                </Link>
            </div>
            <div className={`phone-menu ${showMenu && 'visible'}`} onClick={e => handleMenuToggle(e)}>
                <div className="phone-menu-links">
                    <div className="menu-text">
                        <h2>Dragonfly <br /> Dream Peonies</h2>
                    </div>
                    <div className="link-container">

                    <Link to={'/shop'}>Shop</Link>
                    </div>
                    <div className="link-container">
                    <Link to={'/gallery'}>Gallery</Link>
                    </div>
                    <div className="link-container">
                    <Link to={'/gallery'}>Services</Link>
                    </div>
                    <div className="link-container">
                    <Link to={'/gallery'}>Events</Link>
                    </div>
                    <div className="link-container">
                    <Link to={'/gallery'}>About</Link>
                    </div>
                    <div className="link-container">
                    <Link to={'/gallery'}>Contact</Link>
                    </div>
                </div>
                <div className="phone-menu-exit">

                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar